export const Tojson = (value, key, fileName, downloadJson) => {
  if (!value || !key) return;
  const finalData = value.map((data) => {
    const rag = {};
    data.map((item, index) => {
      rag[key[index]] = item;
    });
    // console.log(rag);
    return rag;
  });
  if (finalData) {
    downloadJson(finalData, fileName);
    console.log('finalData', finalData);
    // return finalData;
  }
};

export const downloadJson = (finalData, fileName) => {
  var dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(finalData));
  var dlAnchorElem = document.getElementById('downloadAnchorElem');
  console.log(dlAnchorElem, '12');

  if (dlAnchorElem) {
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', `${fileName}.json`);
    dlAnchorElem.click();
    console.log('hii', dlAnchorElem);
  }
};
