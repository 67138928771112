import React from 'react';
import './App.css';
import Extension from "./Extension";

function App() {
  return (
      <Extension/>
  );
}

export default App;
